import { useEffect, useRef, useState } from 'react';
import appleInitialize from '../../../../utils/Payments/FreedomPay/appleInitialize';
import CircularProgress from '@mui/material/CircularProgress';
import getCalculateObject from '../../../../utils/API/getCalculateObject';
import fetchFPCalculate from '../../../../utils/Payments/FreedomPay/fetchFPCalculate';
import authorize, {
  AuthorizeBilling,
  AuthorizePayload
} from '../../../../utils/Payments/FreedomPay/authorize';
import extractSessionKey from '../../../../utils/Payments/FreedomPay/extractSessionKey';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { PricingOptions } from '../../../Cart/types';
import { OrderType } from '../../../../types/order';
import {
  GatewayResponse,
  PaymentGatewayType,
  PaymentMethodType
} from '../../types/GatewayResponse';
import extractNameFromDataString from '../../../../utils/Payments/FreedomPay/extractNameFromDataString';
import { GatewayError } from '../../GatewayError';

interface ApplePayProps {
  siteId: string;
  storeId: string;
  menuId: number;
  orderId: string;
  orderTimeStamp: string;
  transactionTotal: number;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const ApplePay = ({
  siteId,
  storeId,
  menuId,
  orderId,
  orderTimeStamp,
  transactionTotal,
  onSuccess,
  onError
}: ApplePayProps) => {
  const [iframeHtml, setIframeHtml] = useState<string>('');
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const { items: cartItems, priceToDisplay } = useCartV2();

  useEffect(() => {
    const initializeApplePay = async () => {
      const payload = {
        TotalPrice: transactionTotal.toFixed(2)
      };

      try {
        const response = await appleInitialize(siteId, payload);
        setIframeHtml(response.iFrame);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        onError(
          new GatewayError(
            error.message,
            PaymentMethodType.ApplePay,
            null,
            null,
            undefined
          )
        );
      }
    };

    initializeApplePay();
  }, [onError, siteId, transactionTotal]);

  useEffect(() => {
    if (iframeContainerRef.current && iframeHtml) {
      const updatedIframeHtml = `
        <style>
          iframe {
            height: 200px !important;
          }
        </style>
        ${iframeHtml}
      `;
      iframeContainerRef.current.innerHTML = updatedIframeHtml;

      const messageEventListener = async (e: MessageEvent) => {
        const message = e.data;
        const data = message.data;

        switch (message.type) {
          case 3:
            try {
              const addressData = JSON.parse(data.attributes[3].Value);
              const calculateObject = getCalculateObject(
                cartItems,
                priceToDisplay === PricingOptions.TAKEOUT
                  ? OrderType.takeOut
                  : OrderType.dineIn,
                null,
                menuId
              );
              const calculateResponse = await fetchFPCalculate(
                storeId,
                calculateObject
              );

              const billTo: AuthorizeBilling = {
                firstName: addressData.givenName,
                lastName: addressData.familyName,
                street1: addressData.addressLines[0] || '',
                street2: addressData.addressLines[1] || '',
                city: addressData.locality,
                state: addressData.administrativeArea,
                postalCode: addressData.postalCode,
                country: addressData.countryCode
              };

              const authorizePayload: AuthorizePayload = {
                PaymentKey: data.paymentKeys[0],
                PosSyncAttemptNumber: 1,
                orderTimeStamp: orderTimeStamp,
                nameOnCard: extractNameFromDataString(data.attributes[3].Value),
                invoiceNumber: orderId.toString(),
                chargeAmount: calculateResponse.chargeAmount.toString(),
                taxTotal: calculateResponse.taxTotal.toString(),
                items: calculateResponse.items,
                bearerSessionKey: extractSessionKey(iframeHtml),
                channel: 'mobile',
                billTo: billTo
              };

              const authorizeResponse = await authorize(
                siteId,
                authorizePayload
              );
              console.log('authorizeResponse: ', authorizeResponse);

              if (authorizeResponse.decision === 'ACCEPT') {
                onSuccess({
                  type: 'apple_pay',
                  amount: transactionTotal,
                  confirmationId: authorizeResponse.requestID,
                  gateway: PaymentGatewayType.freedompay,
                  paymentMethod: PaymentMethodType.ApplePay
                });

                window.FreedomPay.Apm.ApplePay.paymentSuccessful();
              } else {
                window.FreedomPay.Apm.ApplePay.paymentFailed();
                onError(
                  new GatewayError(
                    'unknown',
                    PaymentMethodType.ApplePay,
                    null,
                    null,
                    undefined
                  )
                );
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              window.FreedomPay.Apm.ApplePay.paymentFailed();
              onError(
                new GatewayError(
                  error.message,
                  PaymentMethodType.ApplePay,
                  null,
                  null,
                  undefined
                )
              );
            }
            break;
          default:
            break;
        }
      };

      window.addEventListener('message', messageEventListener);

      return () => {
        window.removeEventListener('message', messageEventListener);
      };
    }
  }, [
    cartItems,
    onError,
    iframeHtml,
    menuId,
    onSuccess,
    orderId,
    orderTimeStamp,
    priceToDisplay,
    siteId,
    storeId,
    transactionTotal
  ]);

  return (
    <>
      {iframeHtml ? (
        <div ref={iframeContainerRef} />
      ) : (
        <CircularProgress size={'1.8rem'} />
      )}
    </>
  );
};

export default ApplePay;
