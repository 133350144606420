import { useEffect, useRef, useState } from 'react';
import googleInitialize from '../../../../utils/Payments/FreedomPay/googleInitialize';
import CircularProgress from '@mui/material/CircularProgress';
import getCalculateObject from '../../../../utils/API/getCalculateObject';
import fetchFPCalculate from '../../../../utils/Payments/FreedomPay/fetchFPCalculate';
import { useCartV2 } from '../../../../hooks/useCartV2';
import authorize, {
  AuthorizeBilling,
  AuthorizePayload
} from '../../../../utils/Payments/FreedomPay/authorize';
import extractSessionKey from '../../../../utils/Payments/FreedomPay/extractSessionKey';
import { PricingOptions } from '../../../Cart/types';
import { OrderType } from '../../../../types/order';
import validate3DS from '../../../../utils/Payments/FreedomPay/validate3DS';
import constructValidatePayload from '../../../../utils/Payments/FreedomPay/constructValidatePayload';
import {
  GatewayResponse,
  PaymentGatewayType,
  PaymentMethodType
} from '../../types/GatewayResponse';
import { GatewayError } from '../../GatewayError';
import { getErrorDialogText } from '../../../../utils/Payments/Stripe/errors';

interface AddressData {
  address1: string;
  address2: string;
  address3: string;
  administrativeArea: string;
  countryCode: string;
  locality: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  sortingCode: string;
}

interface GooglePayProps {
  siteId: string;
  storeId: string;
  menuId: number;
  orderId: string;
  orderTimeStamp: string;
  transactionTotal: number;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const GooglePay = ({
  siteId,
  storeId,
  menuId,
  orderId,
  orderTimeStamp,
  transactionTotal,
  onError,
  onSuccess
}: GooglePayProps) => {
  const { items: cartItems, priceToDisplay } = useCartV2();
  const [iframeHtml, setIframeHtml] = useState<string>('');
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const [validateResponse, setValidateResponse] = useState<boolean | null>(
    null
  );
  const [paymentKey, setPaymentKey] = useState<string | null>(null);
  const [hasProcessed, setHasProcessed] = useState<boolean>(false);
  const [addressData, setAddressData] = useState<AddressData | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const initializeGooglePay = async () => {
      const payload = {
        TotalPrice: transactionTotal.toFixed(2)
      };

      try {
        const response = await googleInitialize(siteId, payload);
        setIframeHtml(response.iFrame);
      } catch (error) {
        onError(
          new GatewayError(
            'initialize_failure',
            PaymentMethodType.GooglePay,
            null,
            null,
            undefined
          )
        );
      }
    };

    initializeGooglePay();
  }, [onError, siteId, transactionTotal]);

  useEffect(() => {
    if (iframeContainerRef.current && iframeHtml) {
      const updatedIframeHtml = `
        <style>
          iframe {
            height: 100% !important;
          }
        </style>
        ${iframeHtml}
      `;
      iframeContainerRef.current.innerHTML = updatedIframeHtml;
    }
  }, [iframeHtml]);

  useEffect(() => {
    const messageEventListener = async (e: MessageEvent) => {
      const message = e.data;
      const data = message.data;

      switch (message.type) {
        case 3: // event listener for getting payment key from iframe
          console.log(`case ${message.type} is hitting: `, data);
          setPaymentKey(data.paymentKeys[0]);
          setAddressData(data.attributes[3].Value);
          setEmail(data.attributes[2].Value);
          break;
        case 14: // 14 and 16 are both for getting 3DS parameters for security validation
        case 16:
          console.log(`case ${message.type} is hitting: `, data);
          try {
            const response = await validate3DS(constructValidatePayload(data));
            setValidateResponse(response);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (err: any) {
            const errorResponse = getErrorDialogText('processing_error');
            onError(
              new GatewayError(
                'declined',
                PaymentMethodType.GooglePay,
                errorResponse.title,
                errorResponse.description,
                undefined
              )
            );
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', messageEventListener);

    return () => {
      window.removeEventListener('message', messageEventListener);
    };
  }, [onError]);

  useEffect(() => {
    const processPayment = async () => {
      if (
        !hasProcessed &&
        validateResponse === true &&
        paymentKey &&
        addressData &&
        addressData.name &&
        email
      ) {
        const nameOnCard = addressData.name;
        console.log('Processing payment...');
        setHasProcessed(true);
        try {
          const calculateObject = getCalculateObject(
            cartItems,
            priceToDisplay === PricingOptions.TAKEOUT
              ? OrderType.takeOut
              : OrderType.dineIn,
            null,
            menuId
          );

          const calculateResponse = await fetchFPCalculate(
            storeId,
            calculateObject
          );

          const billTo: AuthorizeBilling = {
            firstName: addressData.name.split(' ')[0],
            lastName: addressData.name.split(' ')[1],
            street1: addressData.address1,
            street2: addressData.address2 || '',
            city: addressData.locality,
            state: addressData.administrativeArea,
            postalCode: addressData.postalCode,
            country: addressData.countryCode,
            phoneNumber: addressData.phoneNumber,
            email: email
          };

          const authorizePayload: AuthorizePayload = {
            PaymentKey: paymentKey,
            PosSyncAttemptNumber: 1,
            orderTimeStamp,
            nameOnCard,
            invoiceNumber: orderId.toString(),
            chargeAmount: calculateResponse.chargeAmount.toString(),
            taxTotal: calculateResponse.taxTotal.toString(),
            items: calculateResponse.items,
            bearerSessionKey: extractSessionKey(iframeHtml),
            channel: 'mobile',
            billTo: billTo
          };

          const authorizeResponse = await authorize(siteId, authorizePayload);

          if (authorizeResponse.decision === 'ACCEPT') {
            onSuccess({
              type: 'credit_card',
              amount: transactionTotal,
              confirmationId: authorizeResponse.requestID,
              gateway: PaymentGatewayType.freedompay,
              paymentMethod: PaymentMethodType.GooglePay
            });
          } else {
            const errorResponse = getErrorDialogText('processing_error');
            onError(
              new GatewayError(
                'declined',
                PaymentMethodType.GooglePay,
                errorResponse.title,
                errorResponse.description,
                undefined
              )
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          onError(
            new GatewayError(
              err.message,
              PaymentMethodType.GooglePay,
              null,
              null,
              undefined
            )
          );
        }
      }
    };

    processPayment();
  }, [
    cartItems,
    onError,
    hasProcessed,
    iframeHtml,
    menuId,
    onSuccess,
    orderId,
    orderTimeStamp,
    paymentKey,
    priceToDisplay,
    siteId,
    storeId,
    transactionTotal,
    validateResponse,
    addressData,
    email
  ]);
  return (
    <>
      {iframeHtml ? (
        <div ref={iframeContainerRef} />
      ) : (
        <CircularProgress size={'1.8rem'} />
      )}
    </>
  );
};

export default GooglePay;
