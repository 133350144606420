import { styled } from '@mui/material/styles';
import AllergenIcon from '../../../../components/AllergenIcon';
import { FC, ReactElement } from 'react';

import './DropdownItem.scss';
import { MenuModifierItem } from '../../../Menu/types';
import FormatPrice from '../../../Menu/components/FormatPrice/FormatPrice';
import { useTranslation } from 'react-i18next';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { PricingOptions } from '../../../Cart/types';

const AllergensContainer = styled('div')(({}) => ({
  display: 'flex'
}));

const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .drop-down-label-name': {
    position: 'relative',
    top: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14)
  },
  '& .checkBox.withPrices': {
    height: theme.spacing(16)
  },
  '& .checkBox.withPrices .drop-down-label-name': {
    position: 'relative',
    top: theme.spacing(-2),
    fontSize: theme.typography.pxToRem(14)
  },
  '& .radio.withPrices': {
    height: theme.spacing(15)
  },
  '& .radio.withPrices .drop-down-label-name': {
    position: 'relative',
    top: theme.spacing(-2),
    fontSize: theme.typography.pxToRem(14)
  }
}));

const Calories = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 400,
  color: theme.colors.gray[500]
}));

const PriceDisplay = styled('div')(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(-6),
  left: theme.spacing(9.5),
  fontSize: theme.typography.pxToRem(12)
}));

const FormLabel = styled('label')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(24),
  position: 'relative',
  left: theme.spacing(0)
}));

interface DropdownItemProps {
  value: MenuModifierItem;
  isCheckBox?: boolean;
  children: ReactElement<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  className: string;
}

const DropdownItem: FC<DropdownItemProps> = (props) => {
  const { value } = props;
  const { allergens, calories } = value;
  const isCheckBox = props.isCheckBox ?? false;
  const { priceToDisplay } = useCartV2();
  const { t } = useTranslation();
  let name = value.name;

  if (name.includes('No ') && isCheckBox) {
    name = name.replace(/^No\s/, '');
  }

  const priceDisplay =
    priceToDisplay === PricingOptions.TAKEOUT
      ? value.takeOutPrice
      : value.dineInPrice;

  return (
    <ItemContainer>
      <div
        className={`${props.className} ${priceDisplay ? 'withPrices' : ''}`}
        data-testid={`${value.name}-container`}
      >
        <FormLabel data-testid={value.name}>
          {props.children}
          <span className="drop-down-label-name">{name}</span>
          {priceDisplay ? (
            <PriceDisplay data-testid={`${value.name}-${priceDisplay}`}>
              (+ {FormatPrice(priceDisplay)})
            </PriceDisplay>
          ) : null}
        </FormLabel>
      </div>
      <AllergensContainer>
        {allergens?.map((icon, idx) => {
          return (
            <AllergenIcon key={idx} AllergenSVG={icon} height="24" width="24" />
          );
        })}
      </AllergensContainer>
      {calories > 0 && (
        <Calories data-testid={`${value.name}-calories`}>
          {calories} {t('restaurant.menu.cal')}
        </Calories>
      )}
    </ItemContainer>
  );
};

export default DropdownItem;
